.login-header {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #000;
}

.label {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000;
}

.inputField {
  /* width: 532px; */
  display: flex;
  width: 318px;
  padding: 12px 24px;
  align-items: flex-end;
  gap: 8px;
  border-radius: 37px;
  border: 1px solid #121212;
  background: #fff;
  &::-webkit-autofill {
    background-color: transparent !important;
  }
  &:-webkit-autofill {
    background-color: transparent !important;
  }
  &::-ms-clear {
    display: none;
  }
}

.inputField:-webkit-autofill,
.inputField:-webkit-autofill:hover,
.inputField:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  /* You can adjust the border styles for autofill as needed */
}

/* For non-WebKit browsers (Firefox, Edge) */
.inputField:-moz-placeholder {
  background-color: transparent;
}

.inputField:-moz-placeholder:-moz-focusring {
  background-color: transparent;
}

/* For Microsoft Edge */
.inputField:-ms-input-placeholder {
  background-color: transparent;
}

.inputField::placeholder {
  color: rgba(18, 18, 18, 0.48);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 182%; /* 29.12px */
}

.inputField:focus {
  outline: none;
}

.login-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #121212;
  background: #121212;
  width: 100%;
  color: var(--White, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 182%; /* 29.12px */
  padding: 8px 0px;
}

.error-message-bg {
  background-color: #eaeffb;
}

.error-message {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.login-bg {
  width: 600px;
  height: 600px;
}

.back-button {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: transparent;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 182%; /* 29.12px */
}
